<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddDownload"
      :is-valid="formValid">
       <div>
        <vs-tabs>
          <vs-tab :label="$t('DOWNLOAD.TAB.DATA')">
            <div>
              <p>{{$t('DOWNLOAD.ADD_DOWNLOAD.HEADER.TEXT')}}</p>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('DOWNLOAD.ADD_DOWNLOAD.NAME')"  class="mr-3 inputx" :placeholder="$t('DOWNLOAD.PLACEHOLDER.ADD_DOWNLOAD.NAME')"  v-model="data.name" :danger="name_fail" val-icon-danger="clear"/>
              </div>         
              <div class="default-input clearfix align-items-center mb-3">
                <div  class="float-left mr-2">
                  <vs-textarea height="200px" counter="500" :label="$t('DOWNLOAD.LABEL.BESCHREIBUNG')" v-model="data.descr" />
                </div>
              </div>   
            </div>
          </vs-tab>
          <vs-tab :label="$t('DOWNLOAD.TAB.DOWN')">
                            <vs-table
                search
                :data="data.downloadedBy"
                :noDataText="$t('DOWNLOAD.BY.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DOWNLOAD.BY.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('DOWNLOAD.BY.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="partnerNr">
                    {{$t('DOWNLOAD.BY.TABLE.COL2')}}
                  </vs-th>  
                  <vs-th sort-key="date">
                    {{$t('DOWNLOAD.BY.TABLE.COL3')}}
                  </vs-th>                                                                                                                                                            
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >                   
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>   
                    <vs-td :data="tr.partnerNr">
                      {{tr.partnerNr}}
                    </vs-td> 
                    <vs-td :data="tr.date">
                      {{DateToString(tr.date)}}
                    </vs-td>                                                                                                                                                                                                                                  
                  </vs-tr>
                </template>
              </vs-table>
          </vs-tab>
        </vs-tabs>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddDownloadDlg",
  props:[],
  components:{
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('DOWNLOAD.ADD_DOWNLOAD.TITLE'),
      name_fail:false,
      name_ok:false,
      data:this.GetEmptyData()
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('DOWNLOAD.ADD_DOWNLOAD.TITLE');
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.popupTitle = this.$t('DOWNLOAD.ADD_DOWNLOAD.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      accepted(){

          this.$store.dispatch('download/saveDownload', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DOWNLOAD.ADD_DOWNLOAD.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('DOWNLOAD.ADD_DOWNLOAD.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DOWNLOAD.ADD_DOWNLOAD.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          name:"",
          descr:"",
          company_fk:0
        };
      }
  },
  computed:{
    formValid(){
      return this.data.name.length > 0;
    },
  },
  watch:{
  }
};
</script>
<style>
div#promptAddDownload > div.vs-dialog{
  max-width: 500px !important;
}

</style>