<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('DOWNLOAD.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('DOWNLOAD.HEADER.TEXT')}}</span>
        </p>
        <AddDownloadDlg ref="addDownloadDlg" v-on:Success="LoadData"></AddDownloadDlg>
      </vs-card>
    </vs-col>
    <can I="edit" a="download"> 
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12" >
        <vs-card>
          <h2 class="card-title d-flex">{{$t('DOWNLOAD.DROPZONE.TITEL')}}</h2>
          <p class="card-subtitle">
            <span>{{$t('DOWNLOAD.DROPZONE.TEXT')}}</span>
          </p>
            <vue-dropzone id="downloadFileDrop" ref="downloadFileDrop" :options="dropOptions" class="mb-3" 
              v-on:vdropzone-success="dropzoneSuccess" 
              v-on:vdropzone-error="dropzoneError"
            ></vue-dropzone>
        </vs-card>
      </vs-col>
    </can>
  </vs-row>

  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
            <div id="downloads_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="downloads"
                :noDataText="$t('DOWNLOAD.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DOWNLOAD.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('DOWNLOAD.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="descr">
                    {{$t('DOWNLOAD.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="filename">
                    {{$t('DOWNLOAD.TABLE.COL3')}}
                  </vs-th> 
                  <vs-th sort-key="filesize">
                    {{$t('DOWNLOAD.TABLE.COL4')}}
                  </vs-th>                                                                                                                                         
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >                   
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>   
                    <vs-td :data="tr.descr">
                      {{tr.descr}}
                    </vs-td> 
                    <vs-td :data="tr.filename">
                      {{tr.filename}}{{tr.extension}}
                    </vs-td>                                           
                    <vs-td :data="tr.filesize">
                      {{tr.filesize.toFixed(2) + ' ' +tr.filesize_ext}}
                    </vs-td>                                                                                                                
                    <vs-td>
                      <vs-button size="small" icon="get_app" @click="DownloadAttachment(tr.id)"></vs-button>
                      <can I="edit" a="download"> 
                        <vs-button  @click="EditDownload(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                        <vs-button  @click="OpenConfirmDeleteDownload(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                      </can>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import AddDownloadDlg from './components/dialogs/AddDownloadDlg.vue';
import vueDropzone from "vue2-dropzone";
import LocalStorageService from '../services/localstorage.service';
import downloadMethods from '../helper/staticDownloadFuncHelper';

const localStorageService = LocalStorageService.getService();

export default {
  name: "Downloads",
  components: {
    AddDownloadDlg,
    vueDropzone
  },
    data: function (){
      return {
      downloads:[],
      dropOptions: {
        url: this.$appConfig.apiBaseUrl+'/downloads/download/upload',
        maxFilesize: 20, // MB
        maxFiles: 10,
        chunking: false,
        chunkSize: 500, // Bytes
        thumbnailWidth: 50, // px
        thumbnailHeight: 50,
        addRemoveLinks: true,
        headers:{"Authorization":'Bearer ' + localStorageService.getAuthToken()},
        acceptedFiles:".jpg,.png,.bmp,.zip,.pdf,.xls,.xlsx,.doc,.docx",
        forceFallback:false,
        dictDefaultMessage:this.$t('DROPZONE.DEFAULTTEXT'),
        dictFallbackMessage: this.$t('DROPZONE.NOTSUPPORTED'),
        dictFallbackText: this.$t('DROPZONE.FALLBACK_TEXT'),
        dictFileTooBig:this.$t('DROPZONE.TOO_BIG'),
        dictInvalidFileType:this.$t('DROPZONE.INVALID_TYPE'),
        dictResponseError:this.$t('DROPZONE.RESPONSE_ERROR'),
        dictCancelUpload:this.$t('DROPZONE.CANCEL_UPLOAD'),
        dictUploadCanceled:this.$t('DROPZONE.UPLOAD_CANCELED'),
        dictCancelUploadConfirmation:this.$t('DROPZONE.CONFIRM_CANCEL'),
        dictRemoveFile:this.$t('DROPZONE.REMOVE_FILE'),
        dictRemoveFileConfirmation:this.$t('DROPZONE.CONFIRM_REMOVE_FILE'),
        dictMaxFilesExceeded:this.$t('DROPZONE.MAX_FILES')
      },
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.download.downloads.data != null)
      {
        data = this.$store.state.download.downloads.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.download.downloads.status) != 'undefined')
        loading = this.$store.state.download.downloads.status.loading;
      return loading;
    }
  },
  watch: {
      getTableData(value) {
          this.downloads = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#downloads_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#downloads_table'});
        }
      },
    },
  methods: {    
      ...downloadMethods,
      LoadData: function ()
      {
          this.$store.dispatch('download/getDownloads', { root: true },{ root: true }); 
      },
      EditDownload(data){
        this.$refs.addDownloadDlg.InitDlgData(data);
      },
      AddDownload(){
        this.$refs.addDownloadDlg.ShowDlg();
      },  
      OpenConfirmDeleteDownload(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('DOWNLOAD.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('download/deleteDownload', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DOWNLOAD.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('DOWNLOAD.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DOWNLOAD.ERROR.DELETE').replace("*1*",error)});  
        });
      },
          // eslint-disable-next-line no-unused-vars
      dropzoneSuccess(file, response){
        this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DOWNLOAD.SUCCESS.UPLOAD').replace("*1*",file.upload.filename)});
        this.$refs.downloadFileDrop.removeFile(file);
        this.$store.dispatch('download/getDownloads', { root: true },{ root: true });
        this.toggleShowUpload();
      },
      // eslint-disable-next-line no-unused-vars
      dropzoneError(file, message, xhr){
        this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DOWNLOAD.ERROR.UPLOAD').replace("*1*",file.upload.filename).replace("*2*",message)});
        this.$refs.downloadFileDrop.removeFile(file);
      },
      DownloadAttachment(attId)
      {
        this.$store.dispatch('download/downloadFile', attId)
          .then(response => {
              this.DownloadWithFileSaver(response);
            }
          );
      },
  }
};

</script>